<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		v-model="isOpen"
		hide-footer
		dialog-class="dialog-detail-document"
		body-class="dialog-detail-document-body"
		footer-class="p-0"
		size="lg"
		style="width: 75% !important; margin-left: 25% !important"
		@hidden="emitEvent"
	>
		<template #modal-header="{ close }">
			<div class="d-flex align-items-start flex-row w-100">
				<div class="d-flex flex-column w-90">
					<h2>{{ accomodation.acc.name }}</h2>
					<b-button v-if="accomodation.fileName !== ''" variant="light" class="transparent" @click="handleClickViewDocument">
						{{ accomodation.fileName.replaceAll('_', ' ') }}
					</b-button>
				</div>
				<div class="d-flex align-items-start justify-content-end mt-2 w-10">
					<button type="button" aria-label="Close" class="close" @click="close()">×</button>
				</div>
			</div>
		</template>
		<!-- <template #modal-title>
			<h2>{{ accomodation.acc.name }}</h2>
			<b-button v-if="accomodation.fileName !== ''" variant="light" class="transparent" @click="handleClickViewDocument">
				{{ accomodation.fileName.replaceAll('_', ' ') }}
			</b-button>
		</template> -->
		<b-row :style="`margin-top: ${accomodation.fileName === '' ? '25px' : '50px'}`">
			<b-col v-if="accomodation.acc !== undefined && accomodation.acc.description !== ''">
				<p class="p-title">
					{{ FormMSG(1, 'Description') }}
				</p>
				<p>
					{{ accomodation.acc.description }}
				</p>
			</b-col>
		</b-row>
		<b-row v-if="accomodation.publisher">
			<b-col>
				<p class="p-title">
					{{ FormMSG(2, 'Published by') }}
				</p>
				<p>{{ accomodation.publisher.name }} {{ accomodation.publisher.firstName }}</p>
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<p class="p-title">{{ FormMSG(24, 'Publication date') }}</p>
				<p>
					{{ accomodation.acc !== undefined && getDate(accomodation.acc.publishedOn, 'DD MMMM YYYY') }}
					<span class="divider-dot" />
					{{ accomodation.acc !== undefined && getDate(accomodation.acc.publishedOn, 'HH:mm') }}
				</p>
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<p class="p-title">{{ FormMSG(27, 'Reservation date') }}</p>
				<p>
					{{ accomodation.acc !== undefined && getDate(accomodation.startDate, 'DD MMMM YYYY') }}
					<span class="divider-dot" />
					{{ accomodation.acc !== undefined && getDate(accomodation.endDate, 'DD MMMM YYYY') }}
				</p>
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<p class="p-title">{{ FormMSG(29, 'Instructions') }}</p>
				<p>
					{{ accomodation.acc !== undefined && accomodation.acc.specialInstruction ? accomodation.acc.specialInstruction : '-' }}
				</p>
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<p class="p-title fsz-16">
					{{ accomodation.acc !== undefined && accomodation.acc.category === 0 ? FormMSG(30, 'Place Information') : FormMSG(31, 'Routes') }}
				</p>
			</b-col>
		</b-row>
		<b-row v-if="accomodation.acc !== undefined && accomodation.acc.category === 0">
			<b-col cols="4">
				<p>
					<strong>{{ FormMSG(4, 'Name') }}</strong>
				</p>
			</b-col>
			<b-col cols="8">
				{{ accomodation.acc !== undefined && accomodation.acc.name ? accomodation.acc.name : '-' }}
			</b-col>
		</b-row>
		<b-row v-if="accomodation.acc !== undefined && accomodation.acc.category === 0">
			<b-col cols="4">
				<p>
					<strong>{{ FormMSG(5, 'Phone') }}</strong>
				</p>
			</b-col>
			<b-col cols="8" @click="callPhone">
				<span class="text-primary">
					{{ accomodation.acc !== undefined && accomodation.acc.supplier ? accomodation.acc.supplier.phone : '-' }}
				</span>
			</b-col>
		</b-row>
		<b-row v-if="accomodation.acc !== undefined && accomodation.acc.category === 0">
			<b-col cols="4">
				<p>
					<strong>{{ FormMSG(6, 'Email') }}</strong>
				</p>
			</b-col>
			<b-col cols="8">
				{{ accomodation.acc !== undefined && accomodation.acc.supplier ? accomodation.acc.supplier.email : '-' }}
			</b-col>
		</b-row>
		<b-row v-if="accomodation.acc !== undefined && accomodation.acc.category !== 0">
			<b-col cols="4">
				<p>
					<strong>{{ FormMSG(33, 'From') }}</strong>
				</p>
			</b-col>
			<b-col cols="8">
				{{ accomodation.acc !== undefined && accomodation.acc.fromAddress ? accomodation.acc.fromAddress : '-' }}
			</b-col>
		</b-row>
		<b-row v-if="accomodation.acc !== undefined && accomodation.acc.category !== 0">
			<b-col cols="4">
				<p>
					<strong>{{ FormMSG(32, 'To') }}</strong>
				</p>
			</b-col>
			<b-col cols="8" @click="callPhone">
				{{ accomodation.acc !== undefined && accomodation.acc.toAddress ? accomodation.acc.toAddress : '-' }}
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<p class="p-title fsz-16">
					{{ FormMSG(28, 'Address') }}
				</p>
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				{{ accomodation.acc !== undefined && accomodation.acc.supplier ? getAddressFormater(accomodation.acc.supplier.address) : '-' }}
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<b-button variant="light" block @click="handleClickDirection">
					<MapPin color="#06263ED6" :size="16" />
				</b-button>
			</b-col>
		</b-row>
	</b-modal>
</template>

<script>
import moment from 'moment';
import GlobalMixin from '@/mixins/global.mixin';
import languageMessages from '@/mixins/languageMessages';
import { MapPin } from 'lucide-vue';
import { addressFormatter } from '@/shared/fomaters';

export default {
	name: 'DialogDetailAccomodation',

	components: {
		MapPin
	},

	mixins: [GlobalMixin, languageMessages],

	props: {
		open: { type: Boolean, default: false },
		accomodation: { type: Object, default: () => ({}) }
	},

	computed: {
		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		}
	},

	created() {
		moment.locale(this.lang);
	},

	methods: {
		getAddressFormater(address) {
			return addressFormatter(address);
		},
		handleClickDirection() {
			this.$emit('dialog-detail:go-direction', this.accomodation);
		},
		handleClickViewDocument() {
			this.$emit('dialog-detail:view-document', this.accomodation);
		},
		callPhone() {
			this.$emit('dialog-detail:call-phone', this.accomodation);
		},
		emitEvent() {
			this.$emit('dialog-detail:close');
		},
		getDate(value, format) {
			return moment(value).format(format);
		}
	}
};
</script>

<style scoped>
.w-90 {
	width: 90%;
}
.w-10 {
	widows: 10%;
}
</style>
