<template>
	<div class="container-mobile animated fadeIn">
		<div class="form content-contact-mobile">
			<b-row class="mb-3">
				<b-col style="display: flex">
					<div class="form-input-search">
						<b-form-input v-model="filter" type="text" :placeholder="FormMSG(1, 'Type to search')" @input="handleInputFilter" class="search" />
					</div>
					<div class="form-input-icon-search" style="cursor: pointer" @click="clearFilter">
						<search v-if="filter === ''" color="#06263E" :size="16" />
						<x v-else color="#06263E" :size="16" />
					</div>
				</b-col>
			</b-row>
			<b-row class="mb-3">
				<b-col style="display: flex">
					<b-form-select
						v-model="filterByType"
						:options="mapOptions"
						class="mb-3"
						value-field="id"
						text-field="name"
						disabled-field="notEnabled"
						@change="handleChangedFilter"
					></b-form-select>
				</b-col>
			</b-row>
			<b-row class="pt-2">
				<b-col>
					<div class="title-list">
						<h2>{{ FormMSG(21, 'Accomodations') }}</h2>
					</div>
				</b-col>
			</b-row>
			<div v-if="listAccomodations.length === 0" class="text-center">
				<p>{{ FormMSG(22, 'No result') }}</p>
			</div>
			<div v-for="(accomodation, index) in listAccomodations" :key="index" class="cursor-pointer">
				<div v-if="index > 0" class="divider-line" />
				<b-row class="py-3 d-flex align-items-center" :class="{ 'mt-0': index === 0 }">
					<b-col cols="10" class="d-flex flex-column pb-2" style="gap: 4px">
						<p class="name-contact mb-0">
							<strong>{{ accomodation.acc.name }}</strong>
						</p>
						<p class="mb-0">
							<span>
								<span v-html="getIconOfTypeAcc(accomodation)"></span>
							</span>
							&nbsp;
							<span class="status next-day">
								{{ getBetweenDate(accomodation) }}
							</span>
						</p>
					</b-col>
					<b-col cols="2">
						<b-dropdown variant="light" toggle-class="transparent" no-caret dropleft>
							<template #button-content>
								<MoreVertical color="#06263E" :size="20" />
							</template>
							<b-dropdown-item @click="showModal(accomodation)">
								<Eye color="#06263E" :size="16" />
								{{ FormMSG(5, 'See Details') }}
							</b-dropdown-item>
							<b-dropdown-item :disabled="accomodation.acc.supplier.phone === ''" @click="handleDialogDetailCallPhone(accomodation.acc)">
								<Phone :color="accomodation.acc.supplier.phone === '' ? '#818283' : '#06263E'" :size="16" />
								{{ FormMSG(47, 'Call phone') }}
							</b-dropdown-item>
							<b-dropdown-item :disabled="accomodation.acc.supplier.addressId === 0" @click="handleDialogDetailGoDirection(accomodation)">
								<MapPin :color="accomodation.acc.supplier.addressId === 0 ? '#818283' : '#06263E'" :size="16" />
								{{ FormMSG(45, 'See on map') }}
							</b-dropdown-item>
							<b-dropdown-item :disabled="accomodation.fileName === ''" @click="handleDialogDetailViewDocument(accomodation)">
								<Download :color="accomodation.fileName === '' ? '#818283' : '#06263E'" :size="16" />
								{{ FormMSG(46, 'Download') }}
							</b-dropdown-item>
						</b-dropdown>
					</b-col>
				</b-row>
			</div>
			<dialog-detail-accomodation
				:open="showDialogDetail"
				:accomodation="accomodationSelected"
				@dialog-detail:close="closeModal"
				@dialog-detail:call-phone="handleDialogDetailCallPhone"
				@dialog-detail:view-document="handleDialogDetailViewDocument"
				@dialog-detail:go-direction="handleDialogDetailGoDirection"
			/>
		</div>
	</div>
</template>

<script>
import { Search, X, MoreVertical, MapPin, Eye, Download, Phone } from 'lucide-vue';
import languageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import DialogDetailAccomodation from './DialogDetailAccomodation';
import moment from 'moment';
import * as icons from 'lucide-vue';

export default {
	name: 'ListAccomodationsMobile',

	components: {
		Search,
		X,
		MoreVertical,
		MapPin,
		Eye,
		Download,
		Phone,
		DialogDetailAccomodation
	},

	mixins: [languageMessages, GlobalMixin],

	props: {
		accomodations: { type: Array, default: () => [] }
	},

	data() {
		return {
			filter: '',
			listAccomodations: [],
			globalAccomodations: [],
			originaAccomodations: [],
			showDialogDetail: false,
			accomodationSelected: {},
			filterByType: 0
		};
	},

	watch: {
		accomodations: {
			handler(val) {
				this.listAccomodations = val;
				this.globalAccomodations = val;
				this.originaAccomodations = val;
			},
			immediate: true,
			deep: true
		}
	},
	computed: {
		mapOptions() {
			return [
				{ id: 0, name: 'All', disabled: true },
				{ id: 1, name: 'Accommodation' },
				{ id: 2, name: 'Travel' }
			];
		},
		boatOptions() {
			let menus = this.FormMenu(1404);

			return menus;
		},
		busOptions() {
			let menus = this.FormMenu(1405);

			return menus;
		},
		carOptions() {
			let menus = this.FormMenu(1406);

			return menus;
		},
		otherOptions() {
			let menus = this.FormMenu(1407);

			return menus;
		},
		hotelOptions() {
			let menus = this.FormMenu(1415);

			return menus;
		},
		houseOptions() {
			let menus = this.FormMenu(1417);

			return menus;
		},
		appartOptions() {
			let menus = this.FormMenu(1416);

			return menus;
		},
		travelTypeOptions() {
			return [...this.boatOptions, ...this.busOptions, ...this.carOptions, ...this.otherOptions];
		},
		accomodationTypeOptions() {
			return [...this.hotelOptions, ...this.houseOptions, ...this.appartOptions];
		}
	},
	created() {
		moment.locale(this.lang);
	},

	methods: {
		handleChangedFilter(payload) {
			if (payload !== 0) {
				let filterAccomodations = [...this.globalAccomodations].filter((item) => +item.acc.category === +(payload - 1));
				this.listAccomodations = filterAccomodations;
			} else {
				this.listAccomodations = this.globalAccomodations;
			}
		},
		getTextOption(value, options) {
			const text = options.filter((option) => option.value === value);

			return text.length > 0 ? text[0] : '';
		},

		getIconOfTypeAcc(accomodation) {
			let value = +accomodation.acc.type;
			if (accomodation.acc.category === 1) {
				const boat = [...this.boatOptions].filter((option) => +option.value === +value);
				const bus = [...this.busOptions].filter((option) => +option.value === +value);
				const car = [...this.carOptions].filter((option) => +option.value === +value);
				const other = [...this.otherOptions].filter((option) => +option.value === +value);
				if (boat.length !== 0) {
					return '<svg xmlns="http://www.w3.org/2000/svg" class="svg-class" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-ship"><path d="M2 21c.6.5 1.2 1 2.5 1 2.5 0 2.5-2 5-2 1.3 0 1.9.5 2.5 1 .6.5 1.2 1 2.5 1 2.5 0 2.5-2 5-2 1.3 0 1.9.5 2.5 1"/><path d="M19.38 20A11.6 11.6 0 0 0 21 14l-9-4-9 4c0 2.9.94 5.34 2.81 7.76"/><path d="M19 13V7a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v6"/><path d="M12 10v4"/><path d="M12 2v3"/></svg>';
				}
				if (bus.length !== 0) {
					return '<svg xmlns="http://www.w3.org/2000/svg" class="svg-class" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-bus"><path d="M8 6v6"/><path d="M15 6v6"/><path d="M2 12h19.6"/><path d="M18 18h3s.5-1.7.8-2.8c.1-.4.2-.8.2-1.2 0-.4-.1-.8-.2-1.2l-1.4-5C20.1 6.8 19.1 6 18 6H4a2 2 0 0 0-2 2v10h3"/><circle cx="7" cy="18" r="2"/><path d="M9 18h5"/><circle cx="16" cy="18" r="2"/></svg>';
				}
				if (car.length !== 0) {
					return '<svg xmlns="http://www.w3.org/2000/svg" class="svg-class" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-car"><path d="M19 17h2c.6 0 1-.4 1-1v-3c0-.9-.7-1.7-1.5-1.9C18.7 10.6 16 10 16 10s-1.3-1.4-2.2-2.3c-.5-.4-1.1-.7-1.8-.7H5c-.6 0-1.1.4-1.4.9l-1.4 2.9A3.7 3.7 0 0 0 2 12v4c0 .6.4 1 1 1h2"/><circle cx="7" cy="17" r="2"/><path d="M9 17h6"/><circle cx="17" cy="17" r="2"/></svg>';
				} else {
					let type = null;
					if (other[0] !== undefined) {
						type = other[0].text;
						if (type === 'Helicopter') {
							return '<svg xmlns="http://www.w3.org/2000/svg" class="svg-class" height="1em" viewBox="0 0 640 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M128 32c0-17.7 14.3-32 32-32H544c17.7 0 32 14.3 32 32s-14.3 32-32 32H384v64h32c88.4 0 160 71.6 160 160v64c0 17.7-14.3 32-32 32H384 320c-20.1 0-39.1-9.5-51.2-25.6l-71.4-95.2c-3.5-4.7-8.3-8.3-13.7-10.5L47.2 198.1c-9.5-3.8-16.7-12-19.2-22L5 83.9C2.4 73.8 10.1 64 20.5 64H48c10.1 0 19.6 4.7 25.6 12.8L112 128H320V64H160c-17.7 0-32-14.3-32-32zM384 320H512V288c0-53-43-96-96-96H384V320zM630.6 425.4c12.5 12.5 12.5 32.8 0 45.3l-3.9 3.9c-24 24-56.6 37.5-90.5 37.5H256c-17.7 0-32-14.3-32-32s14.3-32 32-32H536.2c17 0 33.3-6.7 45.3-18.7l3.9-3.9c12.5-12.5 32.8-12.5 45.3 0z"/></svg>';
						}
						if (type === 'Metro') {
							return '<svg xmlns="http://www.w3.org/2000/svg" class="svg-class" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-tram-front"><rect width="16" height="16" x="4" y="3" rx="2"/><path d="M4 11h16"/><path d="M12 3v8"/><path d="m8 19-2 3"/><path d="m18 22-2-3"/><path d="M8 15h0"/><path d="M16 15h0"/></svg>';
						}
						if (type === 'Motorcycle') {
							return '<svg xmlns="http://www.w3.org/2000/svg" class="svg-class" height="1em" viewBox="0 0 640 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M280 32c-13.3 0-24 10.7-24 24s10.7 24 24 24h57.7l16.4 30.3L256 192l-45.3-45.3c-12-12-28.3-18.7-45.3-18.7H64c-17.7 0-32 14.3-32 32v32h96c88.4 0 160 71.6 160 160c0 11-1.1 21.7-3.2 32h70.4c-2.1-10.3-3.2-21-3.2-32c0-52.2 25-98.6 63.7-127.8l15.4 28.6C402.4 276.3 384 312 384 352c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128c-13.5 0-26.5 2.1-38.7 6L418.2 128H480c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32H459.6c-7.5 0-14.7 2.6-20.5 7.4L391.7 78.9l-14-26c-7-12.9-20.5-21-35.2-21H280zM462.7 311.2l28.2 52.2c6.3 11.7 20.9 16 32.5 9.7s16-20.9 9.7-32.5l-28.2-52.2c2.3-.3 4.7-.4 7.1-.4c35.3 0 64 28.7 64 64s-28.7 64-64 64s-64-28.7-64-64c0-15.5 5.5-29.7 14.7-40.8zM187.3 376c-9.5 23.5-32.5 40-59.3 40c-35.3 0-64-28.7-64-64s28.7-64 64-64c26.9 0 49.9 16.5 59.3 40h66.4C242.5 268.8 190.5 224 128 224C57.3 224 0 281.3 0 352s57.3 128 128 128c62.5 0 114.5-44.8 125.8-104H187.3zM128 384a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"/></svg>';
						}
						if (type === 'Plane') {
							return '<svg xmlns="http://www.w3.org/2000/svg" class="svg-class" height="1em" viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M482.3 192c34.2 0 93.7 29 93.7 64c0 36-59.5 64-93.7 64l-116.6 0L265.2 495.9c-5.7 10-16.3 16.1-27.8 16.1l-56.2 0c-10.6 0-18.3-10.2-15.4-20.4l49-171.6L112 320 68.8 377.6c-3 4-7.8 6.4-12.8 6.4l-42 0c-7.8 0-14-6.3-14-14c0-1.3 .2-2.6 .5-3.9L32 256 .5 145.9c-.4-1.3-.5-2.6-.5-3.9c0-7.8 6.3-14 14-14l42 0c5 0 9.8 2.4 12.8 6.4L112 192l102.9 0-49-171.6C162.9 10.2 170.6 0 181.2 0l56.2 0c11.5 0 22.1 6.2 27.8 16.1L365.7 192l116.6 0z"/></svg>';
						}
						if (type === 'Scooter') {
							return '<svg xmlns="http://www.w3.org/2000/svg" class="svg-class" height="1em" viewBox="0 0 640 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M280 32c-13.3 0-24 10.7-24 24s10.7 24 24 24h57.7l16.4 30.3L256 192l-45.3-45.3c-12-12-28.3-18.7-45.3-18.7H64c-17.7 0-32 14.3-32 32v32h96c88.4 0 160 71.6 160 160c0 11-1.1 21.7-3.2 32h70.4c-2.1-10.3-3.2-21-3.2-32c0-52.2 25-98.6 63.7-127.8l15.4 28.6C402.4 276.3 384 312 384 352c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128c-13.5 0-26.5 2.1-38.7 6L418.2 128H480c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32H459.6c-7.5 0-14.7 2.6-20.5 7.4L391.7 78.9l-14-26c-7-12.9-20.5-21-35.2-21H280zM462.7 311.2l28.2 52.2c6.3 11.7 20.9 16 32.5 9.7s16-20.9 9.7-32.5l-28.2-52.2c2.3-.3 4.7-.4 7.1-.4c35.3 0 64 28.7 64 64s-28.7 64-64 64s-64-28.7-64-64c0-15.5 5.5-29.7 14.7-40.8zM187.3 376c-9.5 23.5-32.5 40-59.3 40c-35.3 0-64-28.7-64-64s28.7-64 64-64c26.9 0 49.9 16.5 59.3 40h66.4C242.5 268.8 190.5 224 128 224C57.3 224 0 281.3 0 352s57.3 128 128 128c62.5 0 114.5-44.8 125.8-104H187.3zM128 384a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"/></svg>';
						}
						if (type === 'Taxi') {
							return '<svg xmlns="http://www.w3.org/2000/svg" class="svg-class" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-car-taxi-front"><path d="M10 2h4"/><path d="m21 8-2 2-1.5-3.7A2 2 0 0 0 15.646 5H8.4a2 2 0 0 0-1.903 1.257L5 10 3 8"/><path d="M7 14h.01"/><path d="M17 14h.01"/><rect width="18" height="8" x="3" y="10" rx="2"/><path d="M5 18v2"/><path d="M19 18v2"/></svg>';
						}
						if (type === 'Train') {
							return '<svg xmlns="http://www.w3.org/2000/svg" class="svg-class" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M96 0C43 0 0 43 0 96V352c0 48 35.2 87.7 81.1 94.9l-46 46C28.1 499.9 33.1 512 43 512H82.7c8.5 0 16.6-3.4 22.6-9.4L160 448H288l54.6 54.6c6 6 14.1 9.4 22.6 9.4H405c10 0 15-12.1 7.9-19.1l-46-46c46-7.1 81.1-46.9 81.1-94.9V96c0-53-43-96-96-96H96zM64 96c0-17.7 14.3-32 32-32H352c17.7 0 32 14.3 32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V96zM224 288a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/></svg>';
						}
						if (type === 'Van/ truck') {
							return '<svg xmlns="http://www.w3.org/2000/svg" class="svg-class" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-truck"><path d="M5 18H3c-.6 0-1-.4-1-1V7c0-.6.4-1 1-1h10c.6 0 1 .4 1 1v11"/><path d="M14 9h4l4 4v4c0 .6-.4 1-1 1h-2"/><circle cx="7" cy="18" r="2"/><path d="M15 18H9"/><circle cx="17" cy="18" r="2"/></svg>';
						} else {
							return '<svg xmlns="http://www.w3.org/2000/svg" class="svg-class" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-recycle"><path d="M7 19H4.815a1.83 1.83 0 0 1-1.57-.881 1.785 1.785 0 0 1-.004-1.784L7.196 9.5"/><path d="M11 19h8.203a1.83 1.83 0 0 0 1.556-.89 1.784 1.784 0 0 0 0-1.775l-1.226-2.12"/><path d="m14 16-3 3 3 3"/><path d="M8.293 13.596 7.196 9.5 3.1 10.598"/><path d="m9.344 5.811 1.093-1.892A1.83 1.83 0 0 1 11.985 3a1.784 1.784 0 0 1 1.546.888l3.943 6.843"/><path d="m13.378 9.633 4.096 1.098 1.097-4.096"/></svg>';
						}
					}
					return '<svg xmlns="http://www.w3.org/2000/svg" class="svg-class" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-recycle"><path d="M7 19H4.815a1.83 1.83 0 0 1-1.57-.881 1.785 1.785 0 0 1-.004-1.784L7.196 9.5"/><path d="M11 19h8.203a1.83 1.83 0 0 0 1.556-.89 1.784 1.784 0 0 0 0-1.775l-1.226-2.12"/><path d="m14 16-3 3 3 3"/><path d="M8.293 13.596 7.196 9.5 3.1 10.598"/><path d="m9.344 5.811 1.093-1.892A1.83 1.83 0 0 1 11.985 3a1.784 1.784 0 0 1 1.546.888l3.943 6.843"/><path d="m13.378 9.633 4.096 1.098 1.097-4.096"/></svg>';
				}
			} else {
				const hotel = [...this.hotelOptions].filter((option) => +option.value === +value);
				const house = [...this.houseOptions].filter((option) => +option.value === +value);
				const appartment = [...this.appartOptions].filter((option) => +option.value === +value);
				if (hotel.length !== 0) {
					return '<svg xmlns="http://www.w3.org/2000/svg" class="svg-class" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-bed-double"><path d="M2 20v-8a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v8"/><path d="M4 10V6a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v4"/><path d="M12 4v6"/><path d="M2 18h20"/></svg>';
				}
				if (house.length !== 0) {
					return '<svg xmlns="http://www.w3.org/2000/svg" class="svg-class" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-home"><path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"/><polyline points="9 22 9 12 15 12 15 22"/></svg>';
				}
				if (appartment.length !== 0) {
					return '<svg xmlns="http://www.w3.org/2000/svg" class="svg-class" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M64 48c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16h80V400c0-26.5 21.5-48 48-48s48 21.5 48 48v64h80c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16H64zM0 64C0 28.7 28.7 0 64 0H320c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zm88 40c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16v48c0 8.8-7.2 16-16 16H104c-8.8 0-16-7.2-16-16V104zM232 88h48c8.8 0 16 7.2 16 16v48c0 8.8-7.2 16-16 16H232c-8.8 0-16-7.2-16-16V104c0-8.8 7.2-16 16-16zM88 232c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16v48c0 8.8-7.2 16-16 16H104c-8.8 0-16-7.2-16-16V232zm144-16h48c8.8 0 16 7.2 16 16v48c0 8.8-7.2 16-16 16H232c-8.8 0-16-7.2-16-16V232c0-8.8 7.2-16 16-16z"/></svg>';
				}
			}
		},
		getBetweenDate(accomodation) {
			const startDate = new Date(accomodation.startDate);
			const endDate = new Date(accomodation.endDate);

			return moment(startDate).format('DD MMMM YYYY') + ' - ' + moment(endDate).format('DD MMMM YYYY');
		},
		handleDialogDetailGoDirection(payload) {
			this.$emit('list-accomodation:go-direction', payload);
		},
		handleDialogDetailViewDocument(payload) {
			let fileConfirmation = payload.confirmation;
			let _payload = { ...payload.acc, fileConfirmation };
			this.$emit('list-accomodation:view-document', _payload);
		},
		handleDialogDetailCallPhone(payload) {
			this.$emit('list-accomodation:call-phone', payload.supplier.phone);
		},
		showModal(accomodation) {
			this.accomodationSelected = accomodation;
			this.showDialogDetail = true;
		},
		closeModal() {
			this.showDialogDetail = false;
		},
		handleInputFilter(value) {
			if (value !== undefined) {
				if (value.length !== 0) {
					this.filterByType = 0;
				}
				let filter = value.trim().toLowerCase();
				if (filter === '') this.originaAccomodations;
				this.listAccomodations = this.originaAccomodations.filter((accomodation) => accomodation.acc.name.toLowerCase().indexOf(filter) > -1);
			}
		},
		clearFilter() {
			if (this.filter !== '') {
				this.filter = '';
				this.handleInputFilter('');
			}
		}
	}
};
</script>

<style>
.svg-class {
	font-size: 1.2rem !important;
}
</style>
